<template>
  <div class="view-faq">
    <div class="header-img-box">
      <img class="w100" src="@/assets/images/all/pc-icon19.png" alt="" srcset="">
    </div>
    <div class="faq-list">
      <div v-for="item,index in faqList" :key="index" class="faq-item">
        <div class="faq-header flex-s">
          <div class="q">
            <img class="w100" src="@/assets/images/all/pc-icon5.png" alt="" srcset="">
          </div>
          <div class="title">
            {{item.q}}
          </div>
        </div>
        <div class="text-box">
          {{item.a}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faqList: [
        {
          q: 'What’s your MOQ? How many colors I can choose?',
          a: 'The MOQ is one 20’ container with 4 colors from the E- catalog.'
        },
        {
          q: 'Do you provide free sample?',
          a: 'Yes, free sample will send to you in 2-7 days by express to customer.'
        },
        {
          q: 'How many Decor Paper patterns do you how?',
          a: 'We have thousands of patterns for your option'
        },
        {
          q: 'What is your payment terms',
          a: 'T/T, L/C, according to order value.'
        },
        {
          q: 'What about your lead time?',
          a: 'Honestly, It depends on the order quantity and the season you place the order.The normal delivery time is around 25-40 days.'
        },
        {
          q: 'Can you do the unilin and Valinge click system for pvc vinyl click floor? ',
          a: 'We have both the unilin and Valinge click system for your choice.'
        },
        {
          q: 'Can you provide the floor accessories?',
          a: 'Yes. we also provide matching profiles such as skirting, EVA sheet or cork etc.'
        },
        {
          q: 'Can you OEM or ODM?',
          a: 'Yes, the products can be made according to your request.'
        },
        {
          q: 'How do you guarantee the quality of your PVC flooring?',
          a: 'Each step is strictly controlled by the QC team to make sure all our products turn out great.'
        },
        {
          q: 'What is vinyl flooring?',
          a: 'Vinyl flooring is a kind of durable, water-resistant synthetic flooring that has the look of hardwood or stone without high price tag. Our vinyls floors are easy to maintain, scratch resistant, stain resistant, anti-bacterial, water resistant, slip resistant, sound absorption, flame retardant, high elasticity, eco-friendly and extremely high wear resistant designed to last for years.'
        },
        {
          q: 'Is vinyl flooring safe?',
          a: 'All our vinyl flooring brands are certified to meet the international standards of VOC emissions and are certified safe for humans, kids, toddlers and pets.'
        },
        {
          q: 'Is vinyl flooring durable?',
          a: 'Yes, vinyl flooring is known for its durability. It is resistant to scratches, stains, and water damage, making it suitable for high-traffic areas.'
        },
        {
          q: 'How long can vinyl flooring last?',
          a: 'The average life of vinyl flooring is about 10-20 years. However, if it is not maintained properly, it would break down earlier. Similarly, if maintained and managed well, it can last more than 20 years.'
        },
        // {
        //   q: 'What is the difference between laminate flooring and vinyl flooring?',
        //   a: 'Laminate flooring is a type of flooring that is made from wooden layers. It is subject to various issues such as susceptibility to water and termite infestation. Upon contact with water, especially when water seeps through the seams, it will result in warping. On the other hand, water does not seep through vinyl flooring as it is a kind of PVC plastic. It also does not have termite infestation issues due to it being a synthetic material.'
        // },
        {
          q: 'Can vinyl flooring be repaired if damaged?',
          a: 'Vinyl flooring is generally resistant to minor damage, but it may need to be replaced if a plank or tile is severely damaged. Individual pieces can be removed and replaced relatively quickly without disrupting the rest of the floor.'
        },
        {
          q: 'Can vinyl flooring be installed over existing floors?',
          a: 'In many cases, vinyl flooring can be installed directly over existing floors, such as ceramic tiles or hardwood, as long as the surface is clean, smooth, and in good condition.'
        },
        {
          q: 'Is it cheaper to buy from an ID/Renovation company?',
          a: 'No, as they need to make a profit to cover for the services that they are rendering.'
        },
        {
          q: 'Can vinyl flooring be installed on uneven surfaces?',
          a: 'Click type should not be installed over surfaces with the unevenness of 40% product thickness The glue-down type will adhere to the uneven surface.'
        },
        {
          q: 'How can you differentiate between a Premium Vinyl Flooring from an inferior one?',
          a: 'All Premium Vinyl Flooring factories will have an Exact,( not just matching ) design/colour wall skirting and floor profiles to compliment the vinyl flooring they manufacture.'
        },
      ]
    };
  }
}
</script>

<style lang="scss">
.view-faq {
  .header-img-box {
    width: 100%;
    margin-bottom: 0.6rem;
  }
  .faq-list {
    padding: 0 3.1rem;
    margin-bottom: 1.5rem;
    .faq-item {
      margin-bottom: 0.6rem;
      .faq-header {
        margin-bottom: 0.2rem;
        .q {
          margin-right: 0.18rem;
          width: 0.6rem;
        }
        .title {
          font-size: 0.3rem;
          font-weight: 600;
        }
      }
      .text-box {
        padding: 0.2rem 0.44rem;
        font-size: 0.2rem;
        font-weight: 400;
        background: rgba(247, 250, 247, 1);
        color: #000000;
        line-height: 0.24rem;
      }
    }
  }
}
</style>